<template>
  <v-container>
    <!-- <v-btn
      fab
      dark
      small
      color="success"
      fixed
      top
      right
      class="toptop"
      @click="newProduct"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn> -->

    <v-btn
      fab
      dark
      small
      color="success"
      fixed
      top
      left
      class="toptop"
      @click="dialog_menu = !dialog_menu"
    >
      <v-icon>mdi-list-box-outline</v-icon>
    </v-btn>

    <v-navigation-drawer
      left
      class="navigation-drawer"
      width="350"
      v-model="dialog_menu"
      app
    >
      <template v-slot:prepend>
        <v-toolbar>
          <v-toolbar-title>Productos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon @click="dialog_menu = !dialog_menu">mdi-close</v-icon>
        </v-toolbar>
      </template>

      <v-treeview
        return-object
        hoverable
        activatable
        :items="items"
        @update:active="updateForm"
        @update:open="updateOpen"
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon v-if="!item.icon">
            {{ open ? "mdi-folder-open" : "mdi-folder" }}
          </v-icon>
          <v-icon :color="item.color" v-else>
            {{ icons[item.icon] }}
          </v-icon>
        </template>
        <template v-slot:label="{ item }">
          <span v-if="(item.active = '1')">{{ item.name }}</span>
          <span class="red--text" v-else>{{ item.name }}</span>
        </template>
      </v-treeview>
    </v-navigation-drawer>

    <v-sheet flat class="mt-3">
      <v-row justify="center">
        <v-col cols="auto" v-if="this.item.code">
          <v-card
            elevation="2"
            outlined
            height="100%"
            max-width="350px"
            class="d-flex flex-column pa-2"
          >
            <v-img
              max-height="350px"
              width="100%"
              contain
              :src="getImagePhoto(this.item.account, this.item.store, this.item.picture)"
              v-if="this.item.picture"
            ></v-img>
            <v-btn
              v-if="this.item.discount > 0"
              color="red"
              elevation="10"
              rounded
              dark
              small
              absolute
              top
              right
              >{{ "-" + this.item.discount + "%" }}</v-btn
            >
            <v-card-title style="word-break: break-word">
              <b>{{ this.item.product_name }}</b>
            </v-card-title>
            <v-card-text
              v-if="this.item.description"
              class="overflow-hidden"
              v-html="this.item.description"
            >
            </v-card-text>
            <v-spacer></v-spacer>
            <v-btn
              v-if="this.item.discount > 0"
              color="red"
              elevation="0"
              text
              dark
              small
              absolute
              bottom
              right
              class="btnprice"
            >
              <span class="text-decoration-line-through">{{
                "$" + parseFloat(this.item.price).toLocaleString()
              }}</span>
            </v-btn>

            <v-card-actions v-if="this.item.pack > 0" class="card-actions">
              <span>{{
                parseFloat(this.item.price_uom).toLocaleString(2) +
                " x " +
                this.item.normalized_uom
              }}</span>
              <v-spacer></v-spacer>
              <span class="font-weight-bold text-lowercase">
                {{
                  "$" +
                  (
                    parseFloat(this.item.price) - parseFloat(this.item.discount_value)
                  ).toLocaleString(2)
                }}
              </span>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col>
          <v-form v-model="valid" ref="itemForm" @submit="item_submit">
            <v-card elevation="0">
              <v-card-title class="pa-0">
                <v-app-bar elevation="2">
                  <v-tabs show-arrows v-model="tab">
                    <v-tab key="product">
                      <v-icon left> mdi-food-apple-outline </v-icon>
                    </v-tab>
                    <v-tab key="note">
                      <v-icon> mdi-note-edit-outline </v-icon>
                    </v-tab>
                    <v-tab key="photo">
                      <v-icon> mdi-camera-plus-outline </v-icon>
                    </v-tab>
                    <v-tab key="label">
                      <v-icon> mdi-file-document-outline </v-icon>
                    </v-tab>
                  </v-tabs>
                </v-app-bar>
              </v-card-title>
              <v-card-text class="pa-2">
                <v-tabs-items v-model="tab">
                  <v-tab-item key="producto">
                    <v-row class="pa-2">
                      <v-col cols="6" md="6">
                        <v-combobox
                          v-model="item.category"
                          id="category"
                          hide-details="auto"
                          label="Categoría"
                          :items="categories"
                          :rules="f_required"
                          outlined
                          required
                          dense
                        />
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-combobox
                          v-model="item.subcategory"
                          id="subcategory"
                          hide-details="auto"
                          label="Sub-categoria"
                          :items="subcategories"
                          :rules="f_required"
                          outlined
                          required
                          dense
                        />
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="item.product_name"
                          id="product_name"
                          hide-details="auto"
                          label="Nombre"
                          outlined
                          required
                          dense
                        />
                      </v-col>

                      <v-col cols="6" md="6">
                        <v-combobox
                          v-model="item.brand"
                          id="brand"
                          hide-details="auto"
                          label="Marca"
                          :items="brands"
                          :rules="f_required"
                          outlined
                          required
                          dense
                          @keyup="returnCap"
                        />
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          v-model="item.reference"
                          id="reference"
                          hide-details="auto"
                          label="Referencia"
                          :rules="f_required"
                          outlined
                          required
                          dense
                          @keyup="returnCap"
                        />
                      </v-col>
                      <v-col cols="3" md="4">
                        <v-text-field
                          class="centered-input"
                          hide-details="auto"
                          :rules="f_required"
                          v-model="item.size"
                          @keypress="onlyNumber"
                          label="Tamaño"
                          outlined
                          required
                          dense
                        />
                      </v-col>
                      <v-col cols="5" md="4">
                        <v-select
                          v-model="item.uom"
                          hide-details="auto"
                          :rules="f_required"
                          item-text="text"
                          item-value="value"
                          :items="uom_lst"
                          label="UOM"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col cols="4" md="4">
                        <v-text-field
                          v-model="item.pack"
                          label="Pack"
                          class="centered-input"
                          hide-details="auto"
                          @keypress="onlyNumber"
                          outlined
                          required
                          dense
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="6" md="4">
                        <v-text-field
                          v-model="item.tax"
                          label="IVA"
                          class="centered-input"
                          hide-details="auto"
                          @keyup="update_values"
                          append-icon="mdi-percent"
                          outlined
                          required
                          dense
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="6" md="4">
                        <v-text-field
                          v-model="cost"
                          label="Costo sin IVA"
                          class="centered-input"
                          hide-details="auto"
                          @keyup="costChange"
                          prepend-inner-icon="mdi-currency-usd"
                          outlined
                          required
                          dense
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="6" md="4">
                        <v-text-field
                          v-model="costTax"
                          label="Costo con IVA"
                          class="centered-input"
                          hide-details="auto"
                          @keyup="costTaxChange"
                          prepend-inner-icon="mdi-currency-usd"
                          outlined
                          required
                          dense
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="6" md="4">
                        <v-text-field
                          v-model="item.utility"
                          label="Margen"
                          class="centered-input"
                          hide-details="auto"
                          @keyup="priceCalculator"
                          type="number"
                          append-icon="mdi-percent"
                          outlined
                          required
                          dense
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="6" md="4">
                        <v-text-field
                          v-model="price"
                          label="Precio sin IVA"
                          class="centered-input"
                          hide-details="auto"
                          @keyup="priceChange"
                          prepend-inner-icon="mdi-currency-usd"
                          outlined
                          required
                          dense
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="6" md="4">
                        <v-text-field
                          v-model="priceTax"
                          label="Precio con IVA"
                          class="centered-input"
                          hide-details="auto"
                          @keyup="priceTaxChange"
                          prepend-inner-icon="mdi-currency-usd"
                          outlined
                          required
                          dense
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="item.sku"
                          hide-details="auto"
                          label="SKU/EAN/CODIGO"
                          append-icon="mdi-barcode-scan"
                          outlined
                          required
                          dense
                        />
                      </v-col>
                      <v-col cols="6" md="4">
                        <v-select
                          v-model="item.shipping_time"
                          hide-details="auto"
                          label="Tiempo de entrega"
                          :items="shipping_time"
                          item-text="text"
                          item-value="value"
                          append-icon="mdi-truck-delivery"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col cols="6" md="4">
                        <v-select
                          v-model="item.active"
                          hide-details="auto"
                          :rules="f_required"
                          item-text="text"
                          item-value="value"
                          :items="active_lst"
                          label="Status"
                          outlined
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item key="note">
                    <v-row>
                      <v-col cols="12" md="12" class="vueEditorCSS">
                        <vue-editor
                          v-model="item.description"
                          :editorToolbar="customToolbar"
                        ></vue-editor>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item key="photo" align="center" justify="center">
                    <v-select
                      v-model="item.main"
                      hide-details="auto"
                      item-text="text"
                      item-value="value"
                      :items="main_lst"
                      label="Destacado"
                      outlined
                      dense
                      class="ma-3"
                    />
                    <v-img
                      contain
                      aspect-ratio="1"
                      class="white"
                      :src="productPhoto"
                      height="300"
                    >
                      <v-fab-transition>
                        <v-btn
                          class="mx-2 btn-upload"
                          fab
                          light
                          small
                          color="secondary"
                          @click="chooseImage"
                        >
                          <v-icon dark> mdi-camera-plus-outline </v-icon>
                        </v-btn>
                      </v-fab-transition>
                    </v-img>
                    <input
                      class="file-input"
                      ref="fileInput"
                      type="file"
                      @input="onSelectFile"
                    />
                  </v-tab-item>
                  <v-tab-item key="label" align="center" justify="center">
                    <v-img
                      contain
                      aspect-ratio="1"
                      class="white"
                      :src="labelPhoto"
                      height="300"
                    >
                      <v-fab-transition>
                        <v-btn
                          class="mx-2 btn-upload"
                          fab
                          light
                          small
                          color="secondary"
                          @click="chooseLabel"
                        >
                          <v-icon dark> mdi-camera-plus-outline </v-icon>
                        </v-btn>
                      </v-fab-transition>
                    </v-img>
                    <input
                      class="file-input"
                      ref="labelInput"
                      type="file"
                      @input="onSelectLabel"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="delete_itm(item)" v-if="!newItem">
                  <span>Borrar</span>
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn :disabled="!valid" type="submit">
                  <span>Guardar</span>
                  <v-icon>mdi-content-save-outline</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-sheet>
    <ProductForm
      :table="table"
      :item="item"
      :dialog="dialog"
      :newItem="newItem"
      :categories="categories"
      :store="store"
      @close="dialog = false"
      :subcategories="subcategories"
      :brands="brands"
      :suppliers="this.supplier_selected.code"
      @refresh="refresh()"
    />

    <v-navigation-drawer
      temporary
      right
      class="navigation-drawer"
      width="480"
      :value="dialog_smart"
      app
    >
      <v-toolbar>
        <v-toolbar-title>Lista maestra</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="dialog_smart = !dialog_smart">mdi-close</v-icon>
      </v-toolbar>
      <v-card height="100vh">
        <v-card-text color="transparent" class="scroll pa-0">
          <v-expansion-panels accordion flat>
            <v-expansion-panel v-for="(item, i) in smart_ctgry" :key="i">
              <v-card tile flat>
                <v-expansion-panel-header>
                  <v-list-item>
                    <v-list-item-title v-text="i"></v-list-item-title>
                  </v-list-item>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list dense>
                    <v-list-item
                      v-for="(child, index) in smart_ctgry[i]"
                      :key="index"
                      @click="add_to_supplier(child)"
                      class="ma-0 pa-0"
                    >
                      <v-list-item-avatar size="64">
                        <v-img
                          cover
                          :src="
                            getImagePhoto(child.account, child.supplier, child.picture)
                          "
                          v-if="child.picture"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-text="child.product_name" />
                        <v-list-item-subtitle>
                          {{ child.size + " " + child.uom }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        {{ "$ " + parseFloat(child.price).toLocaleString() }}
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list>
                </v-expansion-panel-content>
              </v-card>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions class="fixed-footer"> </v-card-actions>
      </v-card>

      <v-bottom-navigation color="secondary" app>
        <v-row justify="center" class="pt-2">
          <v-col md="12" cols="12">
            <v-text-field
              v-model="search_smart"
              hide-details
              append-icon="mdi-magnify"
              outlined
              rounded
              dense
              placeholder="Buscar producto Smart ..."
              @keypress="update_selected()"
            />
          </v-col>
        </v-row>
      </v-bottom-navigation>
    </v-navigation-drawer>

    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando ...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import createItem from "../../utils/createItem.js";
import ProductForm from "../../components/RawMaterialForm.vue";
import createStore from "../../utils/createStore.js";
import { VueEditor } from "vue2-editor";

export default {
  components: { ProductForm, VueEditor },
  data() {
    return {
      main_lst: [
        { text: "NO", value: 0 },
        { text: "SI", value: 1 },
      ],
      shipping_time: [
        { text: "1 día", value: "1" },
        { text: "2 días", value: "2" },
        { text: "3 días", value: "3" },
        { text: "4 días", value: "4" },
        { text: "Mismo día", value: "0" },
      ],
      alerts: false,
      loading_status: false,
      tab: null,
      dialog_confirm: false,
      compressed_image: null,
      compressed_label: null,
      reg_delete: null,
      valid: false,
      customToolbar: [
        // [{ font: [] }],
        // [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        //  [{ header: 1 }, { header: 2 }],
        //[{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
      ],
      active_lst: [
        { value: "1", text: "Activo" },
        { value: "0", text: "InActivo" },
      ],
      uom_lst: [
        { value: "UN", text: "Unidad" },
        { value: "GR", text: "Gramo" },
        { value: "KG", text: "Kilogramo" },
        { value: "LB", text: "Libra" },
        { value: "LT", text: "Litro" },
        { value: "ML", text: "Mililitro" },
        { value: "GL", text: "Galon" },
        { value: "CJ", text: "Caja" },
        { value: "OZ", text: "Onza" },
        { value: "Mes", text: "Mes" },
        { value: "Año", text: "Año" },
        { value: "Día", text: "Día" },
      ],
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length === 4 || "EL código debe tener 4 caracteres.",
      ],
      productPhoto: null,
      chosenPhoto: null,
      labelPhoto: null,
      cost: 0,
      costTax: 0,
      price: 0,
      priceTax: 0,
      utility: 0,
      search_smart: "",
      dialog_menu: true,
      s_expand: true,
      smart_width: 800,
      dialog_smart: false,
      smart_categories: [],
      smart_lst_selected: [],
      header_size: true,
      table: "raw_material",
      search: "",
      search_supplier: "",
      dialog_suppliers: true,
      errors: [],
      store: "",
      storesLst: [],

      dialog: false,
      newItem: false,

      byCategories: [],
      categories: [],
      CategorySelected: [],
      subcategories: [],
      brands: [],
      suppliers: [],
      supplier_dialog: false,
      supplier_selected: createStore(),
      headers: {
        false: [
          {
            text: "",
            align: "center",
            value: "picture",
            width: 50,
            groupable: false,
          },
          {
            text: "Categoría",
            align: "start",
            sortable: true,
            value: "category",
            dataType: "text",
            groupable: true,
          },
          {
            text: "Marca",
            align: "start",
            sortable: true,
            value: "brand",
            dataType: "text",
            groupable: false,
          },
          {
            text: "Producto",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
            groupable: false,
          },
          {
            text: "Tamaño",
            align: "center",
            sortable: true,
            value: "size",
            dataType: "text",
            groupable: false,
          },
          {
            text: "Pack",
            align: "center",
            sortable: true,
            value: "pack",
            dataType: "text",
            groupable: false,
          },
          {
            text: "Costo",
            align: "end",
            sortable: true,
            value: "cost",
            dataType: "number",
            groupable: false,
          },

          {
            text: " x UOM",
            align: "end",
            sortable: true,
            value: "cost_uom",
            dataType: "number",
            groupable: false,
          },
          {
            text: "Precio",
            align: "end",
            sortable: true,
            value: "price",
            dataType: "number",
            groupable: false,
          },
          {
            text: " x UOM",
            align: "end",
            sortable: true,
            value: "price_uom",
            dataType: "number",
            groupable: false,
          },
          {
            text: "%",
            align: "end",
            sortable: true,
            value: "utility",
            dataType: "number",
            groupable: false,
          },
        ],
        true: [
          {
            text: "",
            align: "center",
            value: "picture",
            width: 25,
          },
          {
            text: "Producto",
            align: "start",
            sortable: true,
            value: "product_name",
            dataType: "text",
          },
          {
            text: "Costo",
            align: "end",
            sortable: true,
            value: "cost",
            dataType: "number",
          },
        ],
      },
      headers_suppliers: [
        {
          text: "",
          align: "start",
          sortable: true,
          value: "picture",
          dataType: "text",
          width: 40,
        },
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "name",
          dataType: "text",
        },
      ],
      items: [],
      smart_lst: [],
      smart_ctgry: [],
      item: createItem(),
      edit: false,
      catSelected: "",
      sSelected: "",
      open: [],
    };
  },
  mounted() {
    this.storesLst = window.stores;
    this.get_items();
  },
  computed: {
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    updateForm(itm) {
      if (itm[0].data) {
        this.item = itm[0].data;
        this.cost = parseFloat(this.item.cost).toFixed(2);
        this.priceTax = parseFloat(this.item.price).toFixed(2);
        this.update_values();
        this.compressed_image = null;
        if (this.item.picture) {
          this.productPhoto = this.getImagePhoto(
            this.item.account,
            "000000",
            this.item.picture
          );
        } else {
          this.productPhoto = null;
        }
        if (this.item.label) {
          this.labelPhoto = this.getImagePhoto(
            this.item.account,
            "000000",
            this.item.label
          );
        } else {
          this.labelPhoto = null;
        }
      }
    },
    updateOpen(openItems) {
      this.open = openItems.length ? [openItems[openItems.length - 1]] : [];
    },
    item_submit(e) {
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        var normalizer = 1;
        switch (this.item.uom) {
          case "LB":
            normalizer = 453;
            this.item.normalized_uom = "GR";
            break;
          case "LT":
            normalizer = 1000;
            this.item.normalized_uom = "ML";
            break;
          case "KG":
            normalizer = 1000;
            this.item.normalized_uom = "GR";
            break;
          case "OZ":
            normalizer = 28.34;
            this.item.normalized_uom = "GR";
            break;
          case "GL":
            normalizer = 3785.4;
            this.item.normalized_uom = "ML";
            break;
          case "ML":
            normalizer = 1;
            this.item.normalized_uom = "ML";
            break;
          case "UN":
            normalizer = 1;
            this.item.normalized_uom = "UN";
            break;
          case "CJ":
            normalizer = 1;
            this.item.normalized_uom = "CJ";
            break;
          case "GR":
            normalizer = 1;
            this.item.normalized_uom = "GR";
            break;
          case "Mes":
            normalizer = 1;
            this.item.normalized_uom = "Mes";
            break;
          case "Año":
            normalizer = 1;
            this.item.normalized_uom = "Año";
            break;
          case "Día":
            normalizer = 1;
            this.item.normalized_uom = "Día";
            break;
          default:
            normalizer = 1;
            this.item.normalized_uom = "UN";
        }
        this.item.normalized_size = parseFloat(this.item.size) * normalizer;
        this.item.cost = this.cost;
        this.item.price = this.priceTax;
        this.item.cost_uom =
          parseFloat(this.item.cost) / parseFloat(this.item.normalized_size);
        this.item.price_uom =
          parseFloat(this.priceTax) / parseFloat(this.item.normalized_size);

        //this.item.store = this.store;

        this.item.tax_base = parseFloat(this.price) * parseFloat(this.item.pack);
        this.item.price_amount = parseFloat(this.price) * parseFloat(this.item.pack);
        this.item.price_value = parseFloat(this.priceTax) * parseFloat(this.item.pack);

        this.item.tax_value =
          (parseFloat(this.priceTax) - parseFloat(this.price)) *
          parseFloat(this.item.pack);

        var qry = {
          account: this.$store.state.profile.account,
          table: this.table,
          data: [this.item],
        };
        if (this.compressed_image) {
          qry.photo = this.compressed_image;
        }
        if (this.compressed_label) {
          qry.label = this.compressed_label;
        }
        console.log(qry);
        webserver("put_table", qry, () => {
          this.productPhoto = null;
          this.labelPhoto = null;
          this.loading_status = false;
          //this.get_items();
        });
      }
    },
    returnCap(event) {
      this.item[event.target.id] = event.target.value.toUpperCase();
    },
    onlyNumber($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    costChange($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
      var tax_value = this.item.tax / 100 + 1;
      this.costTax = (parseFloat(this.cost) * parseFloat(tax_value)).toFixed(2);
      var utitem = (parseFloat(this.price) - parseFloat(this.cost)).toFixed(2);
      this.item.utility = ((parseFloat(utitem) / parseFloat(this.price)) * 100).toFixed(
        2
      );
    },
    update_values() {
      //var utility_value = this.item.utility / 100 + 1;
      //this.price = (parseFloat(this.cost) * parseFloat(utility_value)).toFixed(2);
      //this.priceTax = (parseFloat(this.price) * parseFloat(tax_value)).toFixed(2);
      var tax_value = this.item.tax / 100 + 1;
      this.costTax = (parseFloat(this.cost) * parseFloat(tax_value)).toFixed(2);
      this.price = (parseFloat(this.priceTax) / parseFloat(tax_value)).toFixed(2);

      var utitem = (parseFloat(this.price) - parseFloat(this.cost)).toFixed(2);
      console.log("DIFERENECIA", utitem);
      this.item.utility = ((parseFloat(utitem) / parseFloat(this.price)) * 100).toFixed(
        2
      );
    },
    costTaxChange($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
      var tax_value = parseFloat(this.item.tax) / 100 + 1;
      this.cost = (parseFloat(this.costTax) / parseFloat(tax_value)).toFixed(2);
    },
    priceCalculator() {
      var porcent = (100 - this.item.utility) / 100;
      this.price = (parseFloat(this.cost) / porcent).toFixed(2);
      var tax_value = this.item.tax / 100 + 1;
      this.priceTax = (parseFloat(this.price) * parseFloat(tax_value)).toFixed(2);
    },
    priceChange($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
      var tax_value = this.item.tax / 100 + 1;
      this.priceTax = this.price * tax_value;
      var utitem = (parseFloat(this.price) - parseFloat(this.cost)).toFixed(2);
      this.item.utility = ((parseFloat(utitem) / parseFloat(this.price)) * 100).toFixed(
        2
      );
    },
    priceTaxChange($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
      var tax_value = this.item.tax / 100 + 1;
      this.price = (parseFloat(this.priceTax) / parseFloat(tax_value)).toFixed(2);

      var utitem = (parseFloat(this.price) - parseFloat(this.cost)).toFixed(2);
      this.item.utility = ((parseFloat(utitem) / parseFloat(this.price)) * 100).toFixed(
        2
      );
      //this.item.utility = parseFloat((utitem - 1) * 100);
    },
    getImagePhoto: function (account, store, src) {
      var url = "https://" + this.$store.getters.getHost + "/" + account + "/" + src;
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    chooseLabel() {
      this.$refs.labelInput.click();
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          var img = new Image();
          img.onload = () => {
            var prct = 800 / img.width;
            const elem = document.createElement("canvas");
            elem.width = img.width * prct;
            elem.height = img.height * prct;
            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width * prct, img.height * prct);
            var imageUrl = elem.toDataURL("image/webp");
            this.compressed_image = imageUrl;
            this.productPhoto = imageUrl;
            //this.$emit("compressed-image", imageUrl);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.item.picture = files[0].name.toLowerCase();
        //this.$emit("input", files[0].name.toLowerCase());
      }
    },
    onSelectLabel() {
      var input = this.$refs.labelInput;
      var files = input.files;
      if (files && files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          var img = new Image();
          img.onload = () => {
            var prct = 800 / img.width;
            const elem = document.createElement("canvas");
            elem.width = img.width * prct;
            elem.height = img.height * prct;
            const ctx = elem.getContext("2d");
            ctx.drawImage(img, 0, 0, img.width * prct, img.height * prct);
            var imageUrl = elem.toDataURL("image/webp");
            this.labelPhoto = imageUrl;
            this.compressed_label = imageUrl;

            //this.$emit("compressed-image", imageUrl);
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.item.label = files[0].name.toLowerCase();
        //this.$emit("input", files[0].name.toLowerCase());
      }
    },
    newProduct() {
      this.newItem = false;
      this.item = createItem();
      this.store = this.$store.state.company.code;
      this.item.account = this.$store.state.company.account;
      this.item.store = this.$store.state.company.code;
      this.item.supplier = this.supplier_selected.code;
      console.log(this.item);
      this.dialog = true;
    },
    update_selected() {
      this.smart_lst_selected = this.smart_lst;
    },
    onResize() {
      if (window.innerWidth < 769) this.isMobile = true;
      else this.isMobile = false;
    },
    add_to_supplier(e) {
      this.item = { ...e };
      this.store = this.supplier_selected.code;
      this.item.store = this.supplier_selected.code;
      this.item.supplier = this.supplier_selected.code;
      var qry = {
        account: this.$store.state.profile.account,
        table: this.table,
        data: [this.item],
      };
      webserver("put_table", qry, () => {
        this.loading_status = false;
        this.get_items(this.supplier_selected);
      });
    },
    handleClick(data) {
      this.store = data.store;
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    CategoryChange(e) {
      this.CategorySelected = this.byCategories[e];
      console.log(this.CategorySelected);
    },
    get_items() {
      this.items = [];
      this.loading_status = true;
      console.log(this.$store.state.profile);
      var qry = {
        account: this.$store.state.profile.account,
        table: "raw_material",
        filters: [
          // { field: "store", operator: "=", value: this.$store.state.profile.store },
        ],
      };
      console.log(qry);
      webserver("get_table", qry, (data) => {
        console.log(data);
        this.loading_status = false;

        if (data.length !== 0) {
          data.forEach((rg) => {
            if (rg.cost_uom) rg.cost_uom = parseFloat(rg.cost_uom);
          });

          var ctgry = data.reduce(function (r, a) {
            r[a.category] = r[a.category] || [];
            r[a.category].push(a);
            return r;
          }, Object.create(null));

          for (const [key] of Object.entries(ctgry)) {
            ctgry[key].sort(function (a, b) {
              var textA = a.product_name.toUpperCase();
              var textB = b.product_name.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
          }
          this.byCategories = ctgry;
          this.categories = Object.keys(ctgry).sort();

          // for (const [key] of Object.entries(this.categories)) {
          this.categories.forEach((key) => {
            var children = [];
            ctgry[key].forEach((rg) => {
              children.push({
                id: rg.code,
                name: rg.product_name,
                data: rg,
              });
            });

            this.items.push({
              id: key,
              name: key,
              children: children,
            });
          });

          var sbctgry = data.reduce(function (r, a) {
            r[a.subcategory] = r[a.subcategory] || [];
            r[a.subcategory].push(a);
            return r;
          }, Object.create(null));
          this.subcategories = Object.keys(sbctgry).sort();

          var brnd = data.reduce(function (r, a) {
            r[a.brand] = r[a.brand] || [];
            r[a.brand].push(a);
            return r;
          }, Object.create(null));
          this.brands = Object.keys(brnd).sort();
          //this.items = data;
        }

        if (this.$vuetify.breakpoint.xsOnly) {
          this.dialog_suppliers = false;
        }
        this.CategorySelected = this.byCategories[this.sSelected];
      });
    },
    newForm() {
      this.item = createItem();
      this.store = "000000";
      this.item.account = this.$store.state.profile.account;
      this.item.store = "000000";
      this.item.supplier = "SCP";
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
    refresh() {
      this.get_items();
      this.dialog = false;
    },

    changeMenu(e) {
      this.search_smart = "";
      this.smart_lst_selected = this.smart_ctgry[e];
      //console.log(this.smart_lst_selected, this.smart_ctgry, e);
    },
  },
  watch: {
    s_expand: function (e) {
      //console.log(e);
      if (e) {
        this.smart_width = 800;
      } else {
        this.smart_width = 450;
      }
    },
  },
};
</script>

<style>
.file-input {
  display: none;
}

.toptop {
  top: 70px !important;
}

.centered-input input {
  text-align: center;
}

.table-cursor tbody tr:hover {
  cursor: pointer;
}

.v-expansion-panel-header {
  padding: 0px !important;
}
.v-card__title {
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 100;
}
.v-btn__content {
  width: 100% !important;
  white-space: normal !important;
}

.float {
  position: fixed;
  width: 40px;
  height: 40px;
  top: 70px;
  right: 20px;
  background-color: #0c9;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 2;
}

.v-application {
  font-size: 1rem;
  font-weight: 0;
}
</style>
